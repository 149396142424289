@import '@speed/common/src/components/core.scss';
@import '@speed/common/src/assets/styles/common.scss';

body {
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-x pan-y; // Disable pinch zoom in /out
}

.checkout-page-box {
    @include common-page-box();
}

.checkout-loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e4e9ee;
    border-radius: 5px;
    margin: 20px;
    height: 423px;
}

@media (min-width:1024px) {
    body {
        overflow: unset !important;
        padding-right: 0px !important;
    }
}

// React markdown css

.payment-page-markdown {
    p,h1,h2,h3,h4,h5,h6 {
        margin: 0;
    }
}